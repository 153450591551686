<template>
	<div class="template_calendar_settings">
		
		<!-- Template list -->

		<div class="templates" v-if="templates.length > 0">
			<div class="templates__item" 
				v-for="template,index in templates" 
				:key="`template__${index}`">
				<div class="templates__item-title-container"
				
				v-bind:style="{
					'background' : templateBackground(template, 1)
				}">
					<div class="template__title">{{template.title || "Template"}}</div>
					<div class="icon" @click="() => openChangeTemplate(template.id)">
						<span class="iconify" 
							data-icon="material-symbols:chevron-right" 
							data-width="23" data-height="23"></span>
					</div>
				</div>
				<div class="templates__item-properties"	v-bind:style="{
					'border-color' : templateBackground(template, 1)
				}">
				</div>
			</div>
		</div>
		<div class="templates empty" v-else>
			<div class="empty__title">
				No templates. 
			</div>
			<div class="empty__message">
				When creating an event slot, you can save it as a template.
			</div>
		</div>
	</div>
</template>

<script>
import { addOpacityToColor } from '../../scripts/color';

export default {
	name: 'TemplateMenu',

	computed: {
		templates(){
			return this.$store.getters.calendarSlotTemplates
		}
	},

	// created() {
	// 	if (!this.templates || this.templates.length == 0){
	// 		this.$store.dispatch('setup-slot-templates')
	// 	}
	// },

	methods: {
		openChangeTemplate(templateId){
			this.$router.push({
				name: "calendar-settings-template",
				params: {
					templateId: templateId,
				}
			})
		},
		templateBackground(template, opacity) {
			if (!template || !template.cellBackgroundColor)
				return "lavender"
			let isRGB = template.cellBackgroundColor.includes("rgb")
			let isHex = template.cellBackgroundColor.includes("#")
			if (isRGB || isHex)
				return addOpacityToColor(template.cellBackgroundColor, opacity)
			return template.cellBackgroundColor
		},
		addOpacityToColor: addOpacityToColor
	},
};
</script>

<style scoped>
.template_calendar_settings{
	height: 100%;	
}
.templates{
	display: flex;
	flex-direction: column;
	gap: 16px;
	animation: fade .3s;
}

.empty {
	gap: 10px;
	color: grey;
	padding: 0 20px;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.templates__item-title-container{
	padding: 7px 6px;
	display: grid;
	grid-template-columns: calc(100% - 23px - 16px) 23px;
	grid-column-gap: 16px;
}

.templates__item-title-container > * {
	display: flex;
	align-items: center;
}

.templates__item-properties{
	height: 70px;
	border: 2px solid;
	border-top: none;
}

.empty__title{
	font-size: 20px;
	color: black;
}
</style>